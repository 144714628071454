// Importing libraries
import {FC} from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

// Importing images
import {FaLinkedin, FaInstagram} from 'react-icons/fa'

// Importing components
import Logo from '@components/common/Logo';

// Importing css
import '@css/common/footer.css';

interface FooterProps {

}

const Footer: FC<FooterProps> = () => {

    const footerProps = useSpring({
        from: {y: 100, opacity: 0},
        to: {y: 0, opacity: 1}
    });

    return (
        <animated.div style={footerProps}>
            <footer className="footer">
                <nav className="nav footer__nav">
                    <Logo />
                    <ul className="footer__socials__menu">
                        <li className="footer__socials__item">
                            <a className="nav__link" href="https://instagram.com/microlab.dev" target="blank">
                                <FaInstagram />
                            </a>
                        </li>
                        <li className="footer__socials__item">
                            <a className="nav__link" href="https://linkedin.com/microlab.dev" target="blank">
                                <FaLinkedin />
                            </a>
                        </li>
                    </ul>
                </nav>
                <nav className="nav footer__nav">
                    <ul className="footer__nav__menu">
                        <li className="footer__nav__item">
                            <Link className="nav__link footer__nav__link" to="/">Home</Link>
                        </li>
                        <li className="footer__nav__item">
                            <Link className="nav__link footer__nav__link" to="/about">About</Link>
                        </li>
                        <li className="footer__nav__item">
                            <Link className="nav__link footer__nav__link" to="/projects">Projects</Link>
                        </li>
                        <li className="footer__nav__item">
                            <Link className="nav__link footer__nav__link" to="/contact-us">Let's talk</Link>
                        </li>
                    </ul>
                    <small className="footer__info">© 2021 Microlab. All rights reserved. 🍝 Italy 🇮🇹</small>
                </nav>
            </footer>
        </animated.div>
    );
}

export default Footer;