// Importing libraries
import {FC} from 'react'
import {Link} from 'react-router-dom'

// Importing images
import LogoIcon from '@images/logo.svg';

const Logo: FC = () => {
    return (
    <Link to="/" className="logo" >
        <img className="logo__img" width="40" height="40" src={LogoIcon} alt="logo icon" />
        <p className="logo__title">microlab</p>
    </Link>
    );
}

export default Logo;