// Importing libraries
import { Fragment, useLayoutEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

// Importing components & screens
import ScrollToTop from '@components/common/ScrollToTop';
import Cursor from '@components/common/Cursor';
import Header from '@components/common/Header';
import Footer from '@components/common/Footer';
// import ProjectsComponent from '@components/common/Projects';

import Home from '@screens/Home';
import About from '@screens/About';
import Projects from '@screens/Projects';
import ContactUs from '@screens/ContactUs';

// Importing data
import {projects} from './config/data.json'

function App() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <Fragment>
      <ScrollToTop />
      <Cursor />
      <Header />
      <Switch>
        <Route path="/about" component={About} />
        <Route path="/projects" render={() => <Projects projects={projects} title={"Projects"} desc={"Featured Projects"}/>} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default App;
