
// Importing libraries
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

// Importing components
import Spacer from '@components/common/Spacer'
import Logo from '@components/common/Logo'

// Importing css
import '@css/common/header.css';

interface HeaderProps {

}

const Header: FC<HeaderProps> = (props) => {

    let [open, setOpen] = useState(false);
    const [isContactUs, setContactUs] = useState<boolean>(false);

    const headerProps = useSpring({
        from: { y: -100, opacity: 0 },
        to: { y: 0, opacity: 1 },
    });


    useEffect(() => {
        window.location.href.includes('contact-us') ? setContactUs(true) : setContactUs(false);
    }, [window.location.href]);

    type LinkType = {
        title: string,
        to: string,
    };

    const links: Array<LinkType> = [
        {title: "About", to: "/about"},
        {title: "Projects", to: "/projects"},
        {title: "Let's talk", to: "/contact-us"},
    ];


    return (
        <animated.div style={headerProps}>
            <header className="header">
                <nav className="nav header__nav">
                    <Logo />
                    <ul className="header__nav__menu">
                        {!isContactUs ? <button className="contact-us__btn">
                            <Link className="nav__link" to="/contact-us">
                                <p className="bold">Contact us</p>
                            </Link>
                        </button> : null}
                        <div className="hamburger">
                            <ul className={open ? "hamburger__btn --active" : "hamburger__btn"} onClick={() => setOpen(!open)}>
                                <li className="hamburger__line"></li>
                                <li className="hamburger__line"></li>
                                <li className="hamburger__line"></li>
                            </ul>
                            <ul className={open ? "hamburger__menu --open" : "hamburger__menu"}>
                                {links.map((link, index) => (
                                    <li key={index} className="hamburger__item">
                                        <Link onClick={() => setOpen(false)} className="nav__link italic hamburger__link" to={link.to}>{link.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </ul>
                </nav>
            </header>
            <Spacer width={"100%"} height={"5rem"}/>
        </animated.div>
    );
}

export default Header;