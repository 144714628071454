// Importing libraries
import { FC } from 'react'

// Importing css
import '@css/common/popup.css'

interface PopupProps { message: string }

const Popup: FC<PopupProps> = ({ message }) => {
    return (
        <div className="popup">
            <p>{message}</p>
        </div>
    );
}

export default Popup;