// Importing libraries
import {FC, Fragment, useEffect, useLayoutEffect} from 'react'
import { useLocation } from 'react-router-dom'

interface ScrollToTopProps {}

const ScrollToTop: FC<ScrollToTopProps> = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);
    return null
}
  
export default ScrollToTop;