// Importing libraries
import { FC } from 'react'
import { useSpring, animated } from 'react-spring'

// Importing components
import Spacer from '@components/common/Spacer'
import Project, { ProjectType } from '@components/Project';

// Importing css
import '@css/projects.css'
import SectionTop from '@components/common/SectionTop';

interface ProjectsProps {
    projects: Array<ProjectType>,
    title: String,
    desc: String
}

const Projects: FC<ProjectsProps> = ({ projects, title, desc }) => {

    const titleProps = useSpring({
        from: { x: -100, opacity: 0 },
        to: { x: 0, opacity: 1 },
    });

    const descProps = useSpring({
        from: { y: 100, opacity: 0 },
        to: { y: 0, opacity: 1 },
    });

    return (
        <section className="projects">
            <div className="projects__top">
                <animated.div style={titleProps}>
                        <h1 className="title">Projects</h1>
                    </animated.div>
                    <Spacer width={"100%"} height={"20px"}/>
                    <animated.div style={descProps}>
                        <p style={{ borderLeft: '5px solid var(--cornflower-blue)', padding: '0 1rem', }} className="desc light">
                            We worked for different companies and made several web designs, <br /> static/dynamic websites & product redesigns. 
                            So here are listed all <br /> of our projects that we made so far.
                        </p>
                    </animated.div>
            </div>
            <Spacer width={"100%"} height={"5rem"}/>
            <ul className="projects__menu">
                {projects.map((p, index) => (
                    <li key={index} className="project__item">
                        <img style={index % 2 === 0 ? { left: '-300px' } : { right: '-300px' }} src={p.lateralImg} alt="image" className="lateral__img" />
                        <Project key={index} title={p.title} desc={p.desc} href={p.href} url={p.url} lateralImg={p.lateralImg} />
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default Projects;