// Improting libraries
import { FC } from 'react'
import { useSpring, animated } from 'react-spring'

// Importing components
import Spacer from '@components/common/Spacer'

// Importing css
import '@css/about.css';

interface AboutProps {

}

const About: FC<AboutProps> = () => {

    const titleProps = useSpring({
        from: { x: -100, opacity: 0 },
        to: { x: 0, opacity: 1 },
    });

    const descProps = useSpring({
        from: { y: 100, opacity: 0 },
        to: { y: 0, opacity: 1 },
    });

    const offers = [
        { title: 'Web design' },
        { title: 'Website development'},
        { title: 'Branding & rebranding' },
        { title: 'Landing pages' },
        { title: 'Web app development' },
        { title: 'iOS app design & development' },
        { title: 'Wordpress' },
        { title: 'Coming soon: desktop apps & ML'}
    ];

    return (
        <section className="about">
                <animated.div style={titleProps}>
                    <h1 className="title">Hey 👋🏻</h1>
                </animated.div>
                <Spacer width={"100%"} height={"20px"}/>
                <animated.div style={descProps}>
                    <p style={{ borderLeft: '5px solid var(--cornflower-blue)', padding: '0 1rem', }} className="desc light">
                        Who we are? We're a <span className="bold" style={{ color: 'var(--cornflower-blue)', fontSize: '1.5rem' }}>web agency</span> based in Italy 🇮🇹 <br />
                        and we work to provide our customers <span className="bold" style={{ color: 'var(--cornflower-blue)', fontSize: '1.5rem' }}>100% satisfaction</span><br />
                        We're not a simple web agency, we're dream builders. <br />
                        Customers come to us because they are sure to obtain <br />
                        the <span className="bold" style={{ color: 'var(--cornflower-blue)', fontSize: '1.5rem' }}>best</span> immaginable product.
                    </p>
                </animated.div>
            <Spacer width={"100%"} height={"5rem"}/>
            <div className="offers">
            <h3 className="title bold">What we offer</h3>
            <ul className="offers__menu">
                {offers.map((offer, index) => (
                    <li key={index} className="offers__item">
                        <p>{offer.title}</p>
                    </li>
                ))}
            </ul>
            </div>
        </section>
    );
}

export default About;