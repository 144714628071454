// Importing libraries
import { FC, useState, useRef, Fragment } from 'react'
import { useSpring, animated } from 'react-spring'

//Importing components
import Spacer from '@components/common/Spacer'

export interface ProjectType {
    title: string,
    desc: string,
    href: string,
    url: string,
    lateralImg: string,
}

const Project: FC<ProjectType> = ({ title, desc, href, url }) => {
    const [xys, set] = useState([0, 0, 1]);
    const props = useSpring({
        xys, config: {
            mass: 1,
            tension: 26,
            friction: 10,
            clamp: false,
            precision: 0.01,
            velocity: 0,
        }
    });

    const projectArticle: any = useRef(null);

    // Useful functions
    const calc = (x: number, y: number, rect: any) => [
        -(y - rect.top - rect.height / 3) / 5,
        (x - rect.left - rect.width / 3) / 5,
        1
    ];
    const trans = (x: number, y: number, s: number) =>
        `perspective(${4 * 1000}px) rotateX(${x / 6}deg) rotateY(${y / 6}deg) scale(${s})`;

    const moveFromLeft = useSpring({
        from: { x: -100, opacity: 0 },
        to: { x: 0, opacity: 1 },
        // delay: 200
    });

    return (
        <animated.div style={moveFromLeft}>
            <article className="project" ref={projectArticle}>
                <a target="blank" href={href}>
                    <animated.div style={{ transform: props.xys.to(trans) }}>
                        <img className="project__img" width={"80%"} src={url} alt="image"
                            onMouseMove={(e) => {
                                const rect = projectArticle?.current?.getBoundingClientRect();
                                set(calc(e.clientX, e.clientY, rect));
                            }}
                            onMouseLeave={() => set([0, 0, 1])} />
                    </animated.div>
                </a>
            </article>
            <Spacer width={"100%"} height={"2rem"}/>
        </animated.div>
    );
}

export default Project;