import { FC, useRef, useEffect } from 'react'

interface CursorProps {

}

const Cursor: FC<CursorProps> = () => {
    const cursor: any = useRef(null);

    useEffect(() => {
        document.addEventListener('mousemove', (e) => {
            cursor.current.style.transform = `translate(${e.clientX - 10}px, ${e.clientY - 10}px)`;
        });
    }, [])

    return (
        <div ref={cursor} className="cursor"></div>
    );
}

export default Cursor;