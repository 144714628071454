// Importing libraries
import { FC, Fragment } from 'react'
import { useSpring, animated } from 'react-spring'

// Importing components
import Spacer from '@components/common/Spacer'
import SectionTop from '@components/common/SectionTop'
import Project, { ProjectType } from '@components/Project'

interface FeatureProjectsProps {
    projects: Array<ProjectType>
}

const FeaturedProjects: FC<FeatureProjectsProps> = ({projects}) => {
    return (
        <Fragment>
            <SectionTop title={"Projects"} desc={"We worked for different companies and made several web designs, static/dynamic websites & product redesigns. So here are listed all of our projects that we made so far."} info={"Featured projects"}/>
            <Spacer width={"100%"} height={"5rem"}/>
            <ul className="projects__menu">
                {projects.map((p, index) => (
                    <li key={index} className="project__item">
                        <img style={index % 2 === 0 ? { left: '-300px' } : { right: '-300px' }} src={p.lateralImg} alt="image" className="lateral__img" />
                        <Project key={index} title={p.title} desc={p.desc} href={p.href} url={p.url} lateralImg={p.lateralImg} />
                    </li>
                ))}
            </ul>
            <Spacer width={"100%"} height={"7rem"}/>
        </Fragment>
    );
}

export default FeaturedProjects;