// Importing libraries
import { FC } from 'react'
import { animated, useSpring } from 'react-spring'

// Importing components
import Spacer from '@components/common/Spacer'
import FeaturedProjects from '@components/FeaturedProjects'
import NeedHelp from '@components/NeedHelp'

// Importing css
import '@css/home.css'

// Importing images
import { BiMouse } from 'react-icons/bi'

// Importing data
import { projects } from '../config/data.json'

const Home: FC = () => {

    const titleProps = useSpring({
        from: { x: -100, opacity: 0 },
        to: { x: 0, opacity: 1 },
    });

    const descProps = useSpring({
        from: { y: -100, opacity: 0 },
        to: { y: 0, opacity: 1 },
    });

    return (
        <section className="home">
            <div className="line"></div>
            {/* Home background */}
            <div className="home__bg">
                <div className="home__bg__line" ></div>
                <div className="home__bg__line" ></div>
                <div className="home__bg__line" ></div>
                <div className="home__bg__line" ></div>
            </div>
            <main className="main-home">
                <div className="home__left">
                    <animated.div style={descProps}>
                        <p className="desc italic home__desc">Web agency based in Italy 🇮🇹</p>
                    </animated.div>
                    <animated.div style={titleProps}>
                        <h1 className="title home__title">
                            <span style={{color: 'var(--midnight-blue)'}}>We make <br /> amazing</span> <br /> <span style={{ color: 'var(--cornflower-blue)' }}>web apps </span>
                            <span style={{ color: 'var(--maya-blue)' }}>& brands</span>
                        </h1>
                    </animated.div>
                    <animated.div style={{...descProps, margin: "3rem 0 0 0rem"}}>
                        <p className="desc home__desc">
                            <span style={{color: 'var(--cornflower-blue)'}} className="desc bold">"From micro steps to macro ideas". </span> <br />
                            Our catch phrase explains that implementing  <br /> big dream ideas into life is a multi-micro-step journey.
                        </p>
                    </animated.div>
                </div>
                <div className="home__right">
                    <img className="home__img" src="https://microlab.s3.eu-central-1.amazonaws.com/microlab-dev/images/home-image.png" alt="home-image" />
                </div>
            </main>
                <div className="mouse__container">
                    <BiMouse style={{ color: '#b8b9df', fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, 0%)' }} />
                </div>
            <Spacer width={"100%"} height={"7rem"}/>
            {/* Projects */}
            <FeaturedProjects projects={projects.slice(0, 2)} />
            {/* NeedHelp */}
            <NeedHelp title={"Need help?"} desc={"Help"}/>
        </section>
    );
}

export default Home;