import { FC } from 'react'

interface SpacerProps {
    width: String,
    height: String
}

const Spacer: FC<SpacerProps> = ({width, height}) => {
    return (
        <div style={{width: `${width}`, height: `${height}`}}>
        </div>
    )
}

export default Spacer;