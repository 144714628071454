// Importing libraries
import { FC } from 'react'

// Importing components
import Spacer from '@components/common/Spacer';

interface SectionTopProps {
    title: String,
    desc: String,
    info: String
}

const SectionTop: FC<SectionTopProps> = ({title, desc, info}) => {
    return (
        <div className="section-top">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className="title bold section__title">{title}</h2>
                <p className="desc section__info">{info}</p>
            </div>
            <Spacer width={"100%"} height={"30px"} />
            <div className="section__desc__container">
                <p className="desc section__desc">{desc}</p>
            </div>
        </div>
    );
}

export default SectionTop;