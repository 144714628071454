// Importing libraries
import React, { FC, useState, useEffect } from 'react'
import emailjs from 'emailjs-com';
import { useSpring, animated } from 'react-spring';

// Importing components
import Spacer from '@components/common/Spacer'
import Popup from '@components/common/Popup';

// Importing css
import '@css/contact-us.css';

// Importing configs
import {emailUserId as userId} from '../config/config.json'

interface ContactUsProps {

}

type BudgetItem = {
    title: string,
}

const ContactUs: FC<ContactUsProps> = () => {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [budget, setBudget] = useState<BudgetItem>();
    const [message, setMessage] = useState<string>('');

    // Popup open and setOpen
    const [open, _setOpen] = useState<boolean>(false);
    const [messagePopup, setPopupMessage] = useState<string>("");
    const setOpen = (message: string) => {
        _setOpen(true);
        setPopupMessage(message);
        setTimeout(() => {
            _setOpen(false);
        }, 3000);
    }

    // Animation props
    const titleProps = useSpring({ from: { x: -100, opacity: 0 }, to: { x: 0, opacity: 1 }, });
    const descProps = useSpring({ from: { y: 100, opacity: 0 }, to: { y: 0, opacity: 1 }, });
    const formProps = useSpring({ from: { y: 100, opacity: 0 }, to: { y: 0, opacity: 1 }, });

    const budgetsRange: BudgetItem[] = [
        { title: '$1k-$5k' },
        { title: '$5k-$15k' },
        { title: '$15k-$30k' },
        { title: '$30k+' },
    ];

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!budget) {
            setOpen("Select budget 😎");
            return;
        }
        emailjs.send('service_y86724i', 'template_3otxfyl', {from_name: name, message, budget: budget?.title, reply_to: "Microlab"}, userId)
            .then((res) => {
                console.log(res.text);
                setOpen("E-mail sent successfully 😉");
            }, (err) => {
                console.log(err.text);
            });

    }

    return (
        <section className="contact-us">
            {open ? <Popup message={messagePopup} /> : null}
            <animated.div style={titleProps}>
                        <h1 className="title">Let's talk 🤙🏻</h1>
                    </animated.div>
                    <Spacer width={"100%"} height={"20px"}/>
                    <animated.div style={descProps}>
                        <p style={{ borderLeft: '5px solid var(--cornflower-blue)', padding: '0 1rem', }} className="desc light">
                            Send us a message by filling all the form's spaces or by <br />
                            sending an email to <span className="bold" style={{color: 'var(--cornflower-blue)', fontSize: '1.5rem'}}>hello@microlab.dev</span> 
                        </p>
                    </animated.div>
                    <Spacer width={"100%"} height={"50px"}/>
            <animated.div style={formProps}>
                <form onSubmit={handleSubmit} className="contact-us__form">
                    <div className="form__item">
                        <label className="bold contact-us__label" htmlFor="name">Name or company</label>
                        <input onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter name & company" type="text" className="contact-us__input" id="name" autoComplete="off" required={true} />
                    </div>
                    <div className="form__item">
                        <label className="bold contact-us__label" htmlFor="email">Your email</label>
                        <input onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Enter email" type="email" className="contact-us__input" id="email" autoComplete="off" required={true} />
                    </div>
                    <div className="form__item">
                        <label className="bold contact-us__label">Select your project budget</label>
                        <ul className="budget__menu">
                            {budgetsRange.map((b, index) => (
                                <li key={index} className={budget?.title === b?.title ? "budget__item --selected" : "budget__item"} onClick={() => setBudget(b)}>
                                    <p>{b?.title}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="form__item">
                        <label className="bold contact-us__label" htmlFor="message">Tell us about your idea</label>
                        <textarea onChange={(e) => setMessage(e.currentTarget.value)} placeholder="Give us a brief project description" rows={10} cols={30} className="contact-us__input" id="message" autoComplete="off" required={true} />
                    </div>

                    <button style={{ width: '100%' }} type="submit">
                        <p className="bold">Send</p>
                    </button>
                </form>
            </animated.div>
        </section>
    );
}

export default ContactUs;