// Importing libraries
import { FC } from 'react'
import {Link} from 'react-router-dom'

// Importing components
import Spacer from '@components/common/Spacer';

// Importing css
import '@css/need-help.css'
import SectionTop from './common/SectionTop';

interface NeedHelpProps {
    title: String,
    desc: String
}

const NeedHelp: FC<NeedHelpProps> = ({title, desc}) => {
    return (
        <section className="need-help">
            <SectionTop title={"Need help?"} desc={"If you have any questions/doubts feel free to send us an email. Ask us for help by clicking on the button below. We will answer to all of your questions in no time"} info={"Ask help"}/>
            <Spacer width={"100%"} height={"20px"} />

            <div className="need-help__content">
                <h1 className="bold need-help__title">Let's talk</h1>
                <Spacer width={"100%"} height={"40px"} />
                <p className="desc" style={{textAlign: 'center'}}>
                    Get support on a large platform,
                    flesh out your first MVP, <br />
                    go from concept to investment,
                    or take your startup to the next level. <br />
                    Our process wraps around your unique business needs.
                </p>
                <Spacer width={"100%"} height={"20px"} />
                <button className="need-help__btn">
                    <Link to="/contact-us">
                        <p className="bold">Contact us</p>
                    </Link>
                </button>
            </div>
        </section>
    );
}

export default NeedHelp;